<template>
  <div></div>
</template>

<script>
export default {
  name: "pageIndex",
  props: {},
  components: {},
  created() {
    if (this.$root.$session.logado()) {
      this.$router.push({
        name: "home",
      });
    }
    else{
      this.$router.push({
        name: "loginCliente",
      });
    }
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    teste() {},
  },
};
</script>
